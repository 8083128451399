<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="py-0 my-0 text-left">
        <span class="text-h2">Profile</span>
      </v-col>
      <v-col cols="6" class="py-0 my-0 text-right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab 
              color="primary" 
              @click="brandingDialogue = true"
              v-bind="attrs"
              v-on="on"
            ><v-icon> mdi-cog </v-icon></v-btn>
          </template>
          <span>Customise Branding</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="d-flex justify-center">
      <v-card style="max-width: 900px" class="pa-4">
        <v-row>
          <v-col cols="12" class="pa-4 text-center" style="position: relative">
            <!-- Avatar -->
            <v-avatar size="200">
              <img
              @mouseover="showEditProfilePictureOverlay = true"
                :src="
                  profilePictureURL === null
                    ? 'contact-placeholder.jpg'
                    : profilePictureURL
                "
              />
            </v-avatar>
            <!-- Overlay -->
            <div
              v-if="showEditProfilePictureOverlay"
              class="d-flex justify-center align-center" 
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
            >
              <div
                @click="$refs.stakeholderLogoUpload.click()"
                @mouseleave="showEditProfilePictureOverlay = false"
                class="d-flex justify-center align-center" 
                style="background: rgba(240,240,240, 0.6); width: 200px; height: 200px; border-radius: 50%; cursor: pointer;"
              >
                <v-icon color="black" style="font-size: 50px;">mdi-circle-edit-outline</v-icon>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <input
              @change="addNewImgToCropper"
              ref="stakeholderLogoUpload"
              style="display: none"
              type="file"
              accept="image/*"
            />
            <v-dialog v-model="cropperDialog" width="600">
              <v-card class="pa-4">
                <v-col cols="12">
                  <cropper
                    ref="cropper"
                    class="cropper"
                    :src="imgUrl"
                    :stencil-component="$options.components.CircleStencil"
                  />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn color="primary" @click="cropImage">save</v-btn>
                </v-col>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="6" class="py-0 my-0">
            <v-text-field
              outlined
              label="First Name"
              v-model="contactCard.firstName"
            />
          </v-col>
          <v-col cols="6" class="py-0 my-0">
            <v-text-field
              outlined
              label="Surname"
              v-model="contactCard.surname"
            />
          </v-col>
          <v-col cols="6" class="py-0 my-0">
            <v-select
              outlined
              label="Country"
              v-model="contactCard.countryCode"
              :items="countryOptions"
              item-text="name"
              :item-value="'code'"
            />
          </v-col>
          <!-- <v-col cols="6" class="py-0 my-0">
            <v-text-field
              outlined
              label="Phone Number"
              v-model="contactCard.phoneNumber"
            />
          </v-col> -->
          <v-col cols="6" class="py-0 my-0">
            <v-text-field
              outlined
              label="Job Title"
              v-model="contactCard.jobTitle"
            />
          </v-col>
          <v-col cols="6" class="py-0 my-0">
            <v-text-field
              readonly
              outlined
              label="Email"
              v-model="contactCard.email"
            />
          </v-col>
          <v-col cols="12" class="py-0 my-0" v-if="this.$store.state.Permissions.emailAlertsToggle">
            <v-checkbox
              v-model="subscribedSystemAlerts"
              @click="changeUserSetting('SystemAlerts',subscribedSystemAlerts)"
            >
              <template v-slot:label>
                <div>
                  <header>
                    System Alerts
                  </header>
                  <footer>
                    <span class="caption">Get email notifications related to system alerts</span>
                  </footer>
                </div>
              </template>
            </v-checkbox>
          </v-col>

          <v-col cols="12" class="py-0 my-0" v-if="this.$store.state.Permissions.emailArtworkNotifications">
            <v-checkbox
              v-model="subscribedArtworkNotificationAlerts"
              @click="changeUserSetting('EmailPublishedArtwork',subscribedArtworkNotificationAlerts)"
            >
              <template v-slot:label>
                <div>
                  <header>
                    Published Artwork Alerts
                  </header>
                  <footer>
                    <span class="caption">Get email notifications related to new artwork being published (if enabled for campaign)</span>
                  </footer>
                </div>
              </template>
            </v-checkbox>
            
          </v-col>
          <v-col cols="6" class="text-left  py-0 my-2" v-if="false">
            <v-btn color="red" @click="rightToBeForgottenOpen = true" :disabled="pendingRightToBeForgottenRequest()">Right to be Forgotten request</v-btn>
            <div>
              <span v-if="pendingRightToBeForgottenRequest()" class="caption">Request pending</span>
            </div>
          </v-col>
          <v-col cols="6" class="text-right py-0 my-2">
            <v-btn color="primary" @click="updateProfile">Save</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- Right to be Forgotten dialog -->
    <v-dialog v-model="rightToBeForgottenOpen" width="600" :retain-focus="false"
      :persistent="rightToBeForgottenProcessing">
      <v-card>
        <v-card-title class="error">Right to be Forgotten</v-card-title>
        <div class="px-4 py-2">
          <p class="mt-2">
            This will send a request to the admin of this system, to remove your data. You will be sent an email confirming this request and the process that will be followed.
          </p>
          <p class="mt-4">Are you sure you want to proceed?</p>
          <v-card-actions>
            <v-col cols="12" class="text-right pa-0 ma-0">
              <v-btn
                @click="rightToBeForgotten()"
                :loading="rightToBeForgottenProcessing" color="red" class="mr-3">
                Confirm
              </v-btn>
              <v-btn color="primary" :disabled="rightToBeForgottenProcessing"
                @click="rightToBeForgottenOpen = false;">
                Cancel
              </v-btn>
            </v-col>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog width="800" v-model="brandingDialogue">
      <v-card class="mx-auto pb-1">
        <v-card-title class="white--text primary">
          Customise Branding
        </v-card-title>
        <v-row class="ma-4">
          <v-col cols="3">
            <p>Accent 1</p>
            <v-color-picker mode="hexa" v-model="primaryColor" />
          </v-col>
          <v-col cols="3">
            <p>Accent 2</p>
            <v-color-picker mode="hexa" v-model="secondaryColor" />
          </v-col>
          <v-col cols="3">
            <p>Accent 3</p>
            <v-color-picker mode="hexa" v-model="TertiaryColor" />
          </v-col>
          <v-col cols="3">
            <p>Accent 4</p>
            <v-color-picker mode="hexa" v-model="fourthColor" />
          </v-col>
          <v-col cols="6" class="text-left py-0 my-0">
            <v-btn color="secondary" @click="resetColors" fab
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn color="primary" @click="saveColor">save</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CampaignController from "@/services/controllers/Campaign";
import UserController from "@/services/controllers/User";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import countryPhonePrefixes from '../../../../public/country-phone-prefix.json'
import moment from 'moment'

export default {
  components: {
    Cropper,
    CircleStencil,
    countryPhonePrefixes,
  },

  data: () => ({
    showEditProfilePictureOverlay: false,
    contactCard: {},
    countryOptions: countryPhonePrefixes,
    profilePictureURL: null,
    uploadedProfilePicture: null,
    imgUrl: null,
    brandingDialogue: false,
    themeSwitch: false,
    cropperDialog: false,
    primaryColor: "#4AA6D5",
    secondaryColor: "#499F68",
    TertiaryColor: "#F564A9",
    fourthColor: "#a259c8",
    sidebarColorLight: "#FFFFFF",
    sidebarColorDark: "#FFFFFF",
    aboutMe: null,
    croppedImgB64: null,
    subscribedSystemAlerts: null,
    subscribedArtworkNotificationAlerts: null,
    password: null,
    passwordConfirm: null,
    passwordMismatchErr: false,
    userAvatar: null,
    mediaUploadId: 0,
    rightToBeForgottenOpen: false,
    rightToBeForgottenProcessing: false,
    RightToBeForgottenRequestDate: null,
    RightToBeForgottenRequestOpen: false,
  }),

  mounted() {
    UserController.getUserSetting('SystemAlerts').then(res => {
      this.subscribedSystemAlerts = res.data.value;
    });

    UserController.getUserSetting('EmailPublishedArtwork').then(res => {
      this.subscribedArtworkNotificationAlerts = res.data.value;
    });

    UserController.getUserSetting('SystemAlerts').then(res => {
      this.subscribedSystemAlerts = res.data.value;
    });

    this.grabRightToBeForgottenStatus();
    
    this.getUser();
    this.refreshColorPicker()
  },
  
  methods: {

    // pending right to be forgotten request
    pendingRightToBeForgottenRequest() {  
      return this.RightToBeForgottenRequestDate !== null && this.RightToBeForgottenRequestDate !== "" && moment().diff(this.RightToBeForgottenRequestDate, 'days') < 30 && this.RightToBeForgottenRequestOpen
    },

    // helper function to determine if boolean or string value is true or false
    isTrue(value) {
      if (value === true || value === 'true') {
        return true
      } else {
        return false
      }
    },

    grabRightToBeForgottenStatus() {
      UserController.getUserSetting('RightToBeForgottenRequestDate').then(res => {
        this.RightToBeForgottenRequestDate = res.data.value;
      });
      
      UserController.getUserSetting('RightToBeForgottenRequestOpen').then(res => {
        this.RightToBeForgottenRequestOpen = this.isTrue(res.data.value);
      });
    },

    refreshColorPicker() {
      this.primaryColor = this.$vuetify.theme.themes.light.primary;
      this.secondaryColor = this.$vuetify.theme.themes.light.secondary;
      this.TertiaryColor = this.$vuetify.theme.themes.light.tertiary;
      this.fourthColor = this.$vuetify.theme.themes.light.fourth;
      this.sidebarColorLight = this.$vuetify.theme.themes.light.sidebarColorLight;
    },

    resetColors() {
      this.$vuetify.theme.themes.light.primary = "#4AA6D5";
      this.$vuetify.theme.themes.dark.primary = "#4AA6D5";

      this.$vuetify.theme.themes.light.secondary = "#499F68";
      this.$vuetify.theme.themes.dark.secondary = "#499F68";
      
      this.$vuetify.theme.themes.light.tertiary = "#F564A9";
      this.$vuetify.theme.themes.dark.tertiary = "#F564A9";
      
      this.$vuetify.theme.themes.light.fourth = "#a259c8";
      this.$vuetify.theme.themes.dark.fourth = "#a259c8";

      this.refreshColorPicker()

      
      if (typeof Storage !== "undefined") {
        // Code for localStorage/sessionStorage.
        localStorage.setItem("primaryColor", "#4AA6D5");
        localStorage.setItem("secondaryColor", "#499F68");
        localStorage.setItem("tertiaryColor",  "#F564A9");
        localStorage.setItem("fourthColor", "#a259c8");
        // Retrieve value from local storage and assign to variable
        // var myId = localStorage.getItem("keyName");
      } else {
        // Sorry! No Web Storage support..
      }      

      this.$root.$emit(
        "snackbarSuccess",
        "Successfully reset branding colours"
      );
    },
    
    saveColor() {
      this.$vuetify.theme.themes.light.primary = this.primaryColor;
      this.$vuetify.theme.themes.dark.primary = this.primaryColor;

      this.$vuetify.theme.themes.light.secondary = this.secondaryColor;
      this.$vuetify.theme.themes.dark.secondary = this.secondaryColor;
      
      this.$vuetify.theme.themes.light.tertiary = this.TertiaryColor;
      this.$vuetify.theme.themes.dark.tertiary = this.TertiaryColor;
      
      this.$vuetify.theme.themes.light.fourth = this.fourthColor;
      this.$vuetify.theme.themes.dark.fourth = this.fourthColor;

      this.brandingDialogue = false;
      this.$root.$emit("snackbarSuccess", "Successfully updated branding");

      if (typeof Storage !== "undefined") {
        // Code for localStorage/sessionStorage.
        localStorage.setItem("primaryColor", this.primaryColor);
        localStorage.setItem("secondaryColor", this.secondaryColor);
        localStorage.setItem("tertiaryColor", this.TertiaryColor);
        localStorage.setItem("fourthColor", this.fourthColor);
      }
    },

    cropImage() {
      const { canvas } = this.$refs.cropper.getResult();
      let resultFromApi;
      var checkForResult = setInterval(() => {
        if (resultFromApi !== undefined) {
          clearInterval(checkForResult);
          this.mediaUploadId = resultFromApi.id;
          this.profilePictureURL = resultFromApi.url;
          this.imgUrl = null;
          this.uploadedProfilePicture = null;
          this.cropperDialog = false;
        }
      }, 100);
      if (canvas) {
        const form = new FormData();
        canvas.toBlob((blob) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;

            form.append("fileBase64", base64data);
            form.append("campaignId", 0);

            CampaignController.uploadB64(form)
              .then((b64res) => {
                resultFromApi = b64res.data;
              })
              .catch((err) => {
                clearInterval(checkForResult);
              });
          };
        }, "image/png");
      }
    },

    addNewImgToCropper(e) {
      this.uploadedProfilePicture = Object.values(e.target.files)[0]
      this.imgUrl = URL.createObjectURL(this.uploadedProfilePicture)
      this.cropperDialog = true
    },

    getUser() {
      UserController.whoAmI().then((res) => {
        this.contactCard = res.data.contactCard;
        if (res.data.contactCard.profilePicture !== null) {
          this.profilePictureURL = res.data.contactCard.profilePicture.urlThumb;
        }
      });
    },

    rightToBeForgotten() {
      this.rightToBeForgottenProcessing = true;
      UserController.rightToBeForgotten()
        .then((res) => {
          this.getUser();
          this.$root.$emit("snackbarSuccess", "Request sent successfully.");
          this.$store.state.UI.forceUpdateProfile = true
        })
        .catch((err) => {
          this.$root.$emit("snackbarError", ''+err.response.data.message);
        })
        .finally(() => {
          this.rightToBeForgottenProcessing = false;
          this.rightToBeForgottenOpen = false;
          this.grabRightToBeForgottenStatus();
        })
    },

    updateProfile() {
      UserController.editContactCard({
        id: this.contactCard.id,
        firstName: this.contactCard.firstName,
        surname: this.contactCard.surname,
        countryCode: this.contactCard.countryCode,
        phoneNumber: this.contactCard.phoneNumber,
        jobTitle: this.contactCard.jobTitle,
        email: this.contactCard.email,
        stakeholderId: this.contactCard.stakeholderId,
        profilePictureId: this.mediaUploadId,
      })
        .then((res) => {
          this.getUser();
          this.$root.$emit("snackbarSuccess", "Successfully edited profile");
          this.$store.state.UI.forceUpdateProfile = true
        })
        .catch((err) => {
          this.$root.$emit("snackbarError", ''+err.response.data.message);
        });
    },

    changeUserSetting(setting,value) {
      UserController.setUserSetting({
        value: value,
        setting: setting
      })
        .then((res) => {
          this.$root.$emit("snackbarSuccess", setting + " preferences updated.");
        })
        .catch((err) => {
          this.$root.$emit("snackbarError", ''+err.response.data.message);
        });
    },
  },
};
</script>

<style>
  .cropper {
    height: 600px;
  }

  input{
    color: white;
  }

  .v-dialog {
    box-shadow: none;
  }
</style>